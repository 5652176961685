import React from "react";
import { sendFile } from "../services/api";

export default function UploadCV(data) {
  return (
      <form id="uploadCV" method="POST" encType="multipart/form-data">
      {data.labels === true && (
        <div>
          <label className="string required control-label" htmlFor="candidate_name">
            Upload your CV*
            </label>
            <p>Supported Files: pdf, doc, docx, txt and rtf</p>
        </div>
      )}
        <input
          className="form-control-file form-control-sm "
          id="fileCV"
          name="file"
          type="file"
          onChange={sendFile}
        />
      </form>
  );
}
