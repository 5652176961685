import React from "react";
import Slider from "react-slick";
import "./styles/team.css";
import team from "../images/team.jpeg";
import team2 from "../images/team-2.jpeg";
import team3 from "../images/team-3.jpeg";
import team4 from "../images/office4.jpeg";
import team5 from "../images/office2.jpeg";
import team6 from "../images/office3.jpeg";

export default function Team() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 400,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="team">
        <div className="container">
          <h4>Life at flydevs</h4>
        </div>
        <div>
          <Slider {...settings}>
            <div>
              <img className="team-img" src={team2} alt="team" />
            </div>
            <div>
              <img className="team-img" src={team6} alt="team" />
            </div>
            <div>
              <img className="team-img" src={team3} alt="team" />
            </div>
            <div>
              <img className="team-img" src={team4} alt="team" />
            </div>
            <div>
              <img className="team-img" src={team} alt="team" />
            </div>
            <div>
              <img className="team-img" src={team5} alt="team" />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}
