import React from 'react'
import isoLogo from '../images/isologo.png'
import './styles/about.css'
export default function About() {
  return (
    <div className="text row">
      <div className="body col-md-12">
        <h3 className="section-title">
          <img
            className="img-rounded"
            height="30px"
            src={isoLogo}
            alt="FlyDevs Logo"
          />
          About Us
        </h3>
        <br />
        <p>
          We are Flydevs, a digital agency with 10 years of experience designing and developing web and mobile platforms. We are headquartered in Buenos Aires, Argentina, and have clients and partners in Argentina, Chile, Europe, Australia and USA markets of all sizes and verticals. We are an 80 professionals team and growing that loves and enjoys working with the latest technologies. As we are currently working with multiple companies on many different products, we have quite a few projects where someone like you could make a difference.{' '}
          <br /><br />
          <a href="https://www.flydevs.com/">Know us!</a><br /><br />
          <strong>And join this great team!</strong>
        </p>
        <p>
          <strong>
            <em></em>
          </strong>
        </p>
      </div>
    </div>
  )
}
