import $ from "jquery";

export const sendFile = (e) => {
  // get the reference to the actual file in the input
  var theFormFile = $("#fileCV").get()[0].files[0];
  const API_URL = process.env.REACT_APP_API_URL;

  $.ajax({
    url: `${API_URL}/cv`,
    type: "post",
    dataType: "json",
    contentType: "application/json",
    data: JSON.stringify({ name: theFormFile.name, type: theFormFile.type }),
    success: function (data) {
      $.ajax({
        type: "PUT",
        url: data.image.uploadURL,
        headers: { "Content-Type": theFormFile.type },
        processData: false,
        crossDomain: true,
        data: theFormFile,
        success: function () {},
        error: function (err) {
          console.log(err);
        },
      });
    },
    error: function (err) {
      console.log(err);
    },
  });
};
