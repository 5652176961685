import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Apply from "./apply";
import "./styles/job.css";
export default function Job() {
  const { jobId } = useParams();
  const [job, setJob] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    axios.get(`${API_URL}/careers/${jobId}`).then((data) => {
      setJob(data.data.result.Item);
      setLoading(false);
    });
  }, [jobId]);
  console.log(job);
  return (
    <div className="job-container">
      <section>
        <div className="offer-component">
          <div className="row">
            <div className="col content">
              {loading && (
                <div className="spinner-container">
                  <div
                    className="spinner-border text-primary loadingSpinner"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {!loading && (
                <div>
                  <div className="job-categorie">
                    <div className="underscore"></div>
                    <p>{job.department}</p>
                  </div>
                  <h2 className="title" id="title">
                    {job.title}
                  </h2>
                  <div className="department">
                    <span className="title-icon">&lt;</span>
                    <h3 className="alted"> About Us </h3>
                    <span className="title-icon">&gt;</span>
                  </div>
                  <div className="description" id="description">
                    We are Flydevs, a digital agency with 10 years of experience designing and developing web and mobile platforms. We are headquartered in Buenos Aires, Argentina, and have clients and partners in Argentina, Chile, Europe, Australia and USA markets of all sizes and verticals. We are a 80 professionals team and growing that loves and enjoys working with the latest tecnologies.
                  </div>
                  <div className="department">
                    <span className="title-icon">&lt;</span>
                    <h3 className="alted"> We are looking for  </h3>
                    <span className="title-icon">&gt;</span>
                  </div>
                  <div className="description">
                    <p>
                      {job.description.split("\n").map(function (item, idx) {
                        return (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                    </p>
                  </div>
                  <div className="department">
                    <span className="title-icon">&lt;</span>
                    <h3 className="alted"> We offer  </h3>
                    <span className="title-icon">&gt;</span>
                  </div>
                  <div className="description" id="description">
                    <p>
                      {job.weOffer.split("\n").map(function (item, idx) {
                        return (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Apply />
    </div>
  );
}
