import "./App.css";
import { Switch, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/home";
import Job from "./pages/job";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import ScrollToTop from "./components/scrollToTop";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div className="app-container">
      <Router>
        <div>
          <Navbar />
          <ScrollToTop>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/job/:jobId/:slug">
                <Job />
              </Route>
            </Switch>
          </ScrollToTop>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
