import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./styles/jobs.css";

export default function Jobs() {
  const [jobItems, setJobItems] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    axios.get(`${API_URL}/careers`).then((data) => {
      setLoading(false);
      setJobItems(data.data.result.Items);
    });
  }, []);
  return (
    <div className="jobs-section" id="section-join-us">
      <h1 className="section-title">Find the best jobs</h1>
      <div className="categorie">
        <span>{`<`}</span>
        <p>Development</p>
        <span>{`>`}</span>
      </div>
      <div className="grouped-view">
        <div className="jobs-container" id="jobs-list-container">
          <div className="jobs list list-container" id="jobs-list">
            {loading && (
              <div className="spinner-container">
                <div
                  className="spinner-border text-primary loadingSpinner"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {jobItems.length > 0 &&
              jobItems.map((job, index) => (
                <Link
                  className="job-wrapper"
                  key={index}
                  to={`job/${job.id}/${job.slug}`}
                >
                  <div className="job" id="job-583586">
                    <h5 className="title">{job.title}</h5>
                    <div className="info-job">
                      <div className="department"> {job.department} </div>
                      <ul className="meta hidden-xs">
                        <li className="location">{job.location}</li>
                      </ul>
                    </div>
                  </div>
                  <i className="fas fa-arrow-right"></i>
                </Link>
              ))}
            {jobItems.length <= 0 && (
              <div className="empty-state" hidden>
                <h3>No job openings</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
