import React from "react";
import "./styles/footer.css";

export default function Footer() {
  return (
    <div className="container">
      <div className="footer">
        <div className="copyright">
          <p>© Flydevs Agency. All rights reserved.</p>
        </div>
        <div className="social">
          <div className="follow-wrapper">
            <div className="divider"></div>
            <p>Follow us</p>
          </div>
          <a
            href="https://www.instagram.com/flydevs.agency/"
            target="_blank"
            rel="noreferrer"
            className="social-wrapper"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.facebook.com/flydevs"
            target="_blank"
            rel="noreferrer"
            className="social-wrapper"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/flydevs/"
            target="_blank"
            rel="noreferrer"
            className="social-wrapper"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
