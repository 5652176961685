import React from "react";
import { Link } from "react-router-dom";
import logoFlyDevs from "../images/logo-full-white.png";
import "./styles/navbar.css";

export default function Navbar() {
  return (
    <div className="container">
      <nav className="navbar navbar-light bg-black" id="navbar">
        <div className="pull-left">
          <Link className="navbar-brand" to="/">
            <img src={logoFlyDevs} alt="" width="150" height="auto" />
          </Link>
          <ul className="menu nav navbar-nav ">
            <li>
              <a href="/#section-join-us" rel="noreferrer">
                Join our team!
              </a>
            </li>
          </ul>
        </div>
        <div className="pull-right buttons-wrap">
          <ul className="nav navbar-nav socials hidden-xs navbar-right">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/flydevs"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/flydevs"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/flydevs.agency"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
