import React from "react";
import { useParams } from "react-router-dom";
import $ from "jquery";

import UploadCV from "../components/uploadcv";

import "./styles/apply.css";

export default function Apply() {
  const { jobId } = useParams();
  function applicantSubmit() {
    let id = jobId;
    let applicant = {};
    applicant.careerId = id;
    $('input[name^="candidate"]').each(function (i, x) {
      applicant[x.id] = x.value;
    });
    var theFormFile = $("#fileCV").get()[0].files[0];
    applicant["cv"] = "cv";
    applicant["cv_name"] = theFormFile.name;
    applicant["cv_type"] = theFormFile.type;

    const API_URL = process.env.REACT_APP_API_URL;
    $.ajax({
      url: `${API_URL}/applicants`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify(applicant),
      success: function (data) {
        let formElements = $(".form-control");
        Array.from(formElements).forEach(clearFields);
        $("#success-alert")
          .fadeTo(2000, 500)
          .slideUp(500, function () {
            $("#success-alert").slideUp(500);
          });
      },
    });
  }

  function clearFields(value, index, array) {
    value.value = "";
  }

  return (
    <div>
      <div className="apply-form-component">
        <div className="apply-container">
          <div
            className="simple_form new_candidate"
            id="new_candidate"
            acceptCharset="UTF-8"
          >
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name="authenticity_token" value="" />
            <section>
              <div className="col-md-3 description">
                <div className="job-categorie">
                  <div className="underscore"></div>
                  <p>Apply now</p>
                </div>
                <h3>Join Us!</h3>
              </div>
              <div className="col-md-7">
                <div className="form-group string required candidate_name">
                  <input
                    className="string required form-control"
                    required="required"
                    aria-required="true"
                    placeholder="Your full name*"
                    type="text"
                    name="candidate[name]"
                    id="fullname"
                    onfocus="this.value=''; this.style.color='#000000'"
                  />
                </div>
                <div className="form-group email required candidate_email">
                  <input
                    className="string email required form-control"
                    required="required"
                    aria-required="true"
                    placeholder="Your email address*"
                    type="email"
                    name="candidate[email]"
                    id="email"
                  />
                </div>
                <div className="form-group tel required candidate_phone">
                  <input
                    className="string tel required form-control form-control"
                    required="required"
                    aria-required="true"
                    placeholder="Your phone number*"
                    type="tel"
                    name="candidate[phone]"
                    id="phone"
                  />
                </div>
              </div>
            </section>
            <section>
              <div className="col-md-3 subtitle">
                <h3>CV/Resume</h3>
                <p>Share with us your Linkedin profile</p>
              </div>
              <div className="col-md-7">
                <div className="form-group file_preview required candidate_cv">
                  <input
                    className="string required form-control"
                    required="required"
                    aria-required="true"
                    placeholder="Linkedin profile*"
                    type="text"
                    name="candidate[name]"
                    id="linkedin"
                  />
                </div>
              </div>

              <div className="col-md-7 upload">
                <UploadCV labels={true} />
              </div>
            </section>
            <section>
              <div className="col-md-3 subtitle">
                <h3>Cover letter</h3>
              </div>
              <div className="col-md-7">
                <div className="form-group text optional candidate_cover_letter">
                  <textarea
                    rows="5"
                    className="text optional form-control"
                    name="candidate[cover_letter]"
                    id="cover_letter"
                    placeholder="Write your cover letter here"
                  ></textarea>
                </div>
              </div>
            </section>
            <section>
              <div className="col-md-3 subtitle">
                <h3>Questions</h3>
              </div>
              <div id="screening-questions" className="col-md-7 salary">
                <div className="question string_type">
                  <div className="form-group string required candidate_open_question_answers_content">
                    <input
                      className="string required form-control"
                      required="required"
                      aria-required="true"
                      type="number"
                      name="candidate[open_question_answers_attributes][0][content]"
                      id="salary"
                      placeholder="Intended Salary*"
                    />
                  </div>
                </div>

                <div className="question string_type">
                  <div className="form-group string optional candidate_open_question_answers_content">
                    <input
                      className="string optional form-control"
                      type="text"
                      name="candidate[open_question_answers_attributes][1][content]"
                      id="country"
                      placeholder="¿Where do you live?"
                    />
                  </div>
                </div>
              </div>
            </section>

            <div
              id="success-alert"
              className="alert alert-primary thanks-alert"
              role="alert"
            >
              Thanks! We'll be in touch
            </div>

            <section className="closing">
              <input
                type="submit"
                name="commit"
                id="apply"
                value="Send"
                className="btn-send-email"
                onClick={applicantSubmit}
                data-disable-with="Submit application"
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
