import React from "react";
import Info from "../components/info";
import Jobs from "../components/jobs";
import About from "../components/about";
import Team from "../components/team";
import UploadCV from "../components/uploadcv";

export default function Home() {
  return (
    <>
      <div className="section-separator">
        <Info />
      </div>
      <div className="home-container">
        <div className="container">
          <div className="section-separator">
            <About />
          </div>
          <div className="section-separator">
            <Jobs />
          </div>
          <div className="section-separator">
            If your profile does not match any of our active positions, leave us your CV and we will contact you when we have a suitable position :)<br/><br/>
            <div className="col-md-7 upload">
              <UploadCV labels={false} />
            </div>
          </div>
        </div>
      </div>
      <div className="section-separator">
        <Team />
      </div>
    </>
  );
}
