import React from "react";
import "./styles/info.css";

export default function Info() {
  return (
    <div className="info-section">
      <div className="container">
        <div className="info">
          <h2 className="info-title">
            The <span>digital agency </span>
            that makes the difference
          </h2>
        </div>
      </div>
    </div>
  );
}
